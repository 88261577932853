<template>
    <CardComponent
        :title="title"
        :icon="icon"
        link="/purchase/price"
        class="purchase-range"
    >
        <section v-loading="loading">
            <gb-echart
                v-if="chartData.length > 0"
                ref="valuePercent"
                config="purchase-range.config"
                height="100%"
                width="100%"
            />
            <Empty v-else />
        </section>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import GbEchart from '@/components/Echart'
import tradeSvc from 'services/trade'
import Empty from '@/components/empty'
export default {
    name: 'PurchaseRange',
    components: { CardComponent, GbEchart, Empty },
    data () {
        return {
            title: '采购价涨幅',
            icon: 'iconfont icon-zhuzhuangtu',
            chartData: [],
            loading: false
        }
    },
    created () {
        this.getPurchaseIncrease()
    },
    methods: {
        async getPurchaseIncrease () {
            try {
                this.loading = true
                const { data = [] } = await tradeSvc.getPurchaseIncrease()
                // const data = [
                //     {
                //         skuTitle: '22',
                //         brandName: '品牌',
                //         increaseRate: 20,
                //         warehouseName: '仓库22'
                //     },
                //     {
                //         skuTitle: '44',
                //         brandName: '品牌',
                //         increaseRate: 9,
                //         warehouseName: '仓库'
                //     },
                //     {
                //         skuTitle: '55',
                //         brandName: '品牌',
                //         increaseRate: 8,
                //         warehouseName: '仓库'
                //     },
                //     {
                //         skuTitle: 'xxx',
                //         brandName: '品牌',
                //         increaseRate: 19,
                //         warehouseName: '仓库'
                //     },
                //     {
                //         skuTitle: 'xxx',
                //         brandName: '品牌',
                //         increaseRate: 34,
                //         warehouseName: '仓库'
                //     },
                //     {
                //         skuTitle: 'xxx',
                //         brandName: '品牌',
                //         increaseRate: 4,
                //         warehouseName: '仓库'
                //     }
                // ]
                this.chartData = data
                if (this.chartData.length > 0) {
                    let yAxisData = []
                    let seriesData = data.map(item => {
                        item.value = item.increaseRate
                        return item
                    })
                    let wareHouse = []
                    data.forEach(item => {
                        yAxisData.push(`${item.skuTitle}/${item.brandName}`)
                        // seriesData.push(item)
                        wareHouse.push(item.warehouseName)
                    })
                    this.$nextTick(() => {
                        const echart = this.$refs.valuePercent
                        echart.option.yAxis.data = yAxisData.reverse()
                        echart.option.series.data = seriesData.reverse()
                        echart.option.wareHouse = wareHouse.reverse()
                        echart.refresh()
                    })
                }
                this.loading = false
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.purchase-range {
    grid-row-start: span 2;
    grid-column-start: span 2;
}
</style>
